<template>
  <section
    class="button-groups"
    v-class-mod:button-groups="rowLines ? [`row-lines-${rowLines.height}`, `row-lines-${rowLines.rowGap ? 1 : 0}`] : ''"
  >
    <div class="button-groups__container">
      <template v-for="(group, gKey) in layout.groups" :key="gKey">
        <span v-if="layout.spacing.includes(gKey)" class="button-groups__spacing" />
        <div class="button-groups__group">
          <template v-for="(option, tKey) in group" :key="tKey">
            <cta-button-with-options v-if="'options' in option" v-bind="option" class="button-groups__cta-button" />
            <cta-button v-else-if="'title' in option" v-bind="option" class="button-groups__cta-button" />
            <icon-toggle-button v-else-if="'icon' in option" v-bind="option" />
          </template>
        </div>
      </template>
    </div>
  </section>
</template>
<script setup lang="ts">
import { computed } from "vue";

import { ButtonSize } from "@horizon56/styles/types";

import CtaButtonWithOptions, {
  type Props as CtaButtonWithOptionsProps,
} from "@/components/buttons/cta-button-with-options.vue";
import CtaButton, { type CtaButtonProps } from "@/components/buttons/cta-button.vue";
import IconToggleButton, { type IconToggleButtonProps } from "@/components/buttons/icon-toggle-button.vue";

type Seperator = "seperator";
type Spacing = "spacing";

export type Option = CtaButtonProps | CtaButtonWithOptionsProps | IconToggleButtonProps | Seperator | Spacing;

const props = defineProps<{
  options: Option[];
  rowLines?: {
    height: ButtonSize;
    rowGap: boolean;
  };
}>();

const layout = computed(() => {
  const spacing: number[] = [];
  const groups: (CtaButtonProps | IconToggleButtonProps | CtaButtonWithOptionsProps)[][] = [];
  for (const option of props.options) {
    if (typeof option !== "string") {
      if (!Array.isArray(groups[groups.length - 1])) {
        groups.push([]);
      }
      groups[groups.length - 1].push(option);
    } else {
      if (option === "spacing") {
        spacing.push(groups.length);
      }
      groups.push([]);
    }
  }
  return { groups, spacing };
});
</script>

<style lang="scss" scoped>
.button-groups {
  $block: &;
  display: inline-flex;
  &__container {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    column-gap: 10px;
    width: 100%;
    row-gap: var(--row-line-gap);
    overflow: hidden;
  }
  &__group {
    display: flex;
    flex-flow: row nowrap;
    height: var(--row-line-height);
  }
  &__cta-button + #{&}__cta-button {
    margin-left: 10px;
  }
  &__spacing {
    margin: 0 auto;
  }
  @each $size in $buttonSizes {
    &--row-lines-#{$size} #{&}__group {
      --row-line-height: var(--app-button-height-#{$size});
    }
  }
  &[class*="row-lines"] {
    &[class*="row-lines-1"] {
      --row-line-gap: 10px;
    }
    #{$block}__group {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-top: 1px solid var(--black-20);
      }
      @for $i from 1 through 20 {
        &:nth-of-type(#{$i}):before {
          top: calc(
            (var(--row-line-height) * #{$i}) + (var(--row-line-gap) * #{$i - 1}) + (var(--row-line-gap) / 2) - 0.5px
          );
        }
      }
    }
  }

  /*

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: flex;
    left: 10px;
    right: 10px;
    z-index: -1;
    height: var(--app-button-height-large);
    border-top: var(--editor-border);
    border-bottom: var(--editor-border);
  }
  &:before {
    top: var(--app-button-height-large);
  }
  &:after {
    top: calc((var(--app-button-height-large) * 3) + 2px);
  }
  */
}
</style>
